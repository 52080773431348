import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { DateInput } from 'react-admin'; // Assuming you're using react-admin
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
} from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { DateTimeInput } from 'react-admin';
import EditIcon from '@material-ui/icons/Edit'; // An edit icon for the button
import { useUpdate } from 'react-admin';
import { useNotify } from 'react-admin';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            width: '100%',
        },
    })
);
type Props = {
    record: any; // Replace with your actual type for record
    // handleDateChange: (date: string) => {setComment(event.target.value);}; // Callback to handle date change
};
// type Props = {
//     record: Order;
// };
const EditCreatedAt: React.FC<Props> = ({ record }) => {
    const notify = useNotify();

    const classes = useStyles();

    const [isOpen, setIsOpen] = useState(false);
    // const createdAt = record?.createdAt; // Retrieve createdAt from the record
    const [createdAt, setDate] = useState(record?.createdAt);

    // useUpdate hook to send the update request
    const [updateCreatedAt, { loading }] = useUpdate(
        'orders', // The resource
        record.id, // ID of the record to update
        { createdAt, editFlag: true }, // The data to update
        record, // Current record
        {
            undoable: false, // Don't allow undo
            onSuccess: () => {
                notify('Date updated successfully', 'info');
                window.location.reload(); // Reload the page after successful update
            },
            onFailure: () => {
                notify('Error updating date', 'warning');
            },
        }
    );
    const handleDateChange = event => {
        setDate(event.target.value);
    };
    return (
        <>
            <Button
                // variant="outlined"

                color="secondary"
                startIcon={<EditIcon style={{ marginRight: 0 }} />} // Edit icon
                size="small"
                onClick={() => setIsOpen(true)}
                style={{ border: 'none', marginRight: '10px' }}
            >
                {/* Edit Created At */}
            </Button>

            <Dialog
                open={isOpen}
                onClose={() => setIsOpen(false)}
                fullWidth={true}
                style={{ overflowX: 'hidden' }}
            >
                <DialogTitle>Edit Created At</DialogTitle>
                <Form
                    onSubmit={() => {}} // Add submit handler if needed
                    render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <DialogContent style={{ overflowX: 'hidden' }}>
                                <FormControl className={classes.formControl}>
                                    {/* DateInput to edit createdAt */}
                                    <DateTimeInput
                                        locales="en-IN"
                                        source="createdAt"
                                        label="Created At"
                                        value={createdAt}
                                        onChange={handleDateChange}
                                        showTime
                                    />
                                </FormControl>
                            </DialogContent>
                            <DialogActions style={{ overflowX: 'hidden' }}>
                                <Button onClick={() => setIsOpen(false)}>
                                    Cancel
                                </Button>
                                <Button
                                    // type="submit"
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => {
                                        updateCreatedAt();
                                        setIsOpen(false);
                                    }}
                                >
                                    Save
                                </Button>
                            </DialogActions>
                        </form>
                    )}
                />
            </Dialog>
        </>
    );
};

export default EditCreatedAt;
