import React from 'react';
import PropTypes from 'prop-types';
import capitalize from 'capitalize';
import { DateTimeInput } from 'react-admin';

import {
    DateField,
    BooleanField,
    ReferenceField,
    TextField,
} from 'react-admin';
import OnHoldButton from './OnHoldButton';
import EditCreatedAt from './EditCreatedDate';

import { Caption, OrderStatus, OrderType } from '../../constants';
import CityField from '../../components/CityField';
import BoxitChipField from '../../components/BoxitChipField';
import CountryField from '../../components/CountryField';
import UserReferenceField from '../../components/UserReferenceField';
import Money from '../../components/Money';
import TimeSlotField from '../../components/TimeSlotField';
import Address from '../../components/Address';
import MoneyField from '../../components/MoneyField';
import ChipField from '../../components/ChipField';
import Thumbnail from '../../components/Thumbnail';
import VolumeField from '../../components/VolumeField';
import ItemStorageList from '../../orders/components/ItemStorageList';

import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
} from '@material-ui/core';

export function LeadSummary(props) {
    const { record } = props;

    const isPartnerOrder = record.partnerId && record.partnerId !== 1;
    const totalRent = record.customerRent * record.months;
    const canEditDate =
        (JSON.parse(
            localStorage.getItem('permissions') as string
        ) as string[]).findIndex(item => item === 'leads-creation-date') !== -1;
    const discountService =
        record.services &&
        record.services.find(i => i.servicetype.name === 'Discount');
    const discountAmount = discountService ? discountService.amount : 0;
    const deliveryService =
        record.services &&
        record.services.find(
            i => i.servicetype.name === 'Delivery (Charged Later)'
        );
    const deliveryCharge = deliveryService ? deliveryService.amount : 0;
    const services =
        record.services &&
        record.services.filter(
            i =>
                ['Discount', 'Delivery (Charged Later)'].indexOf(
                    i.servicetype.name
                ) === -1
        );
    const totalServiceCharges =
        services &&
        services
            .filter(
                i =>
                    ['2020OFFER', 'JUNE2021'].indexOf(record.couponId) === -1 ||
                    !(
                        ((i.servicetype.name === 'Pickup' ||
                            i.servicetype.name === 'Pickup and Packing') &&
                            record.months >= 3) ||
                        (i.servicetype.name === 'Delivery' &&
                            record.months >= 6)
                    )
            )
            .reduce((sum, i) => sum + Number(i.amount), 0);
    const subTotalAmount = totalRent + totalServiceCharges;
    const totalAmount = totalRent + totalServiceCharges - discountAmount;

    return (
        <div>
            <Table>
                <TableBody>
                    <TableRow hover>
                        <TableCell>Order Type</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <ChipField record={record} source="type" />
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>Partner</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <ReferenceField
                                label="Partner"
                                record={record}
                                source="partnerId"
                                reference="partners"
                                basePath="/partners"
                                link="show"
                            >
                                <TextField source="name" />
                            </ReferenceField>
                        </TableCell>
                    </TableRow>
                    <TableRow hover>
                        <TableCell>Lead No</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.id}
                        </TableCell>
                    </TableRow>
                    {record.quoteVersion && (
                        <TableRow hover>
                            <TableCell>Current Revision</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                {record.quoteVersion}
                            </TableCell>
                        </TableRow>
                    )}
                    {(record.quoteVersionSent || record.quoteVersion) && (
                        <TableRow hover>
                            <TableCell>Revision Sent to Cutomer</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                {record.quoteVersionSent
                                    ? record.quoteVersionSent
                                    : 'Original'}
                            </TableCell>
                        </TableRow>
                    )}
                    <TableRow hover>
                        <TableCell>Order No</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.status !== OrderStatus.new && (
                                <ReferenceField
                                    {...props}
                                    label="Order"
                                    source="id"
                                    reference="orders"
                                    link="show"
                                >
                                    <TextField source="id" />
                                </ReferenceField>
                            )}
                        </TableCell>
                    </TableRow>
                    <TableRow hover>
                        <TableCell>Customer</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <UserReferenceField {...props} label="Customer" />
                        </TableCell>
                    </TableRow>
                    <TableRow hover>
                        <TableCell>Reference</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <TextField record={record} source="ref" />
                        </TableCell>
                    </TableRow>
                    <TableRow hover>
                        <TableCell>Assignee</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <UserReferenceField
                                source="assigneeId"
                                {...props}
                                label="Assignee"
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow hover>
                        <TableCell>City</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <CityField record={record} />
                        </TableCell>
                    </TableRow>
                    <TableRow hover>
                        <TableCell>Country</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <CountryField record={record} />
                        </TableCell>
                    </TableRow>
                    <TableRow hover>
                        <TableCell>Status</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <BoxitChipField
                                record={record}
                                source="status"
                                list="order"
                            />
                        </TableCell>
                    </TableRow>
                    {record &&
                    record.lostReasonId &&
                    record.status === OrderStatus.lost ? (
                        <TableRow hover>
                            <TableCell>Reason</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <ReferenceField
                                    {...props}
                                    label="Reason"
                                    source="lostReasonId"
                                    reference="lost-reasons"
                                    link="show"
                                >
                                    <TextField source="reason" />
                                </ReferenceField>
                            </TableCell>
                        </TableRow>
                    ) : null}
                    {record &&
                    record.holdReasonId &&
                    record.status === OrderStatus.onhold ? (
                        <TableRow hover>
                            <TableCell>Reason</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <ReferenceField
                                    {...props}
                                    label="Reason"
                                    source="holdReasonId"
                                    reference="hold-reasons"
                                    link="show"
                                >
                                    <TextField source="reason" />
                                </ReferenceField>
                            </TableCell>
                        </TableRow>
                    ) : null}
                    {record &&
                    record.rejectReasonId &&
                    record.status === OrderStatus.rejected ? (
                        <TableRow hover>
                            <TableCell>Reason</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <ReferenceField
                                    {...props}
                                    label="Reason"
                                    source="rejectReasonId"
                                    reference="reject-reasons"
                                    link="show"
                                >
                                    <TextField source="reason" />
                                </ReferenceField>
                            </TableCell>
                        </TableRow>
                    ) : null}
                    {(record &&
                        record.reason &&
                        record.status === OrderStatus.onhold) ||
                    record.status === OrderStatus.lost ||
                    record.status === OrderStatus.rejected ? (
                        <TableRow hover>
                            {record.status === OrderStatus.rejected && (
                                <TableCell>{'Comment'}</TableCell>
                            )}
                            {record.status === OrderStatus.onhold && (
                                <TableCell>{'Comment'}</TableCell>
                            )}
                            {record.status === OrderStatus.lost && (
                                <TableCell>{'Comment'}</TableCell>
                            )}
                            <TableCell style={{ textAlign: 'right' }}>
                                {record.reason}
                            </TableCell>
                        </TableRow>
                    ) : null}
                    <TableRow hover>
                        <TableCell>Next Follow Up</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <DateField
                                locales="en-IN"
                                record={record}
                                source="followUpAt"
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow hover>
                        <TableCell>Created At</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record && canEditDate && (
                                <EditCreatedAt record={record} />
                            )}
                            <DateField
                                locales="en-IN"
                                record={record}
                                source="createdAt"
                                showTime
                            />
                            {/* <DateTimeInput source="createdAt" /> */}
                            {/* <OnHoldButton record={record} /> */}
                        </TableCell>
                    </TableRow>
                    <TableRow hover>
                        <TableCell>Quote Sent At</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <DateField
                                locales="en-IN"
                                record={record}
                                source="quoteSentAt"
                                showTime
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow hover>
                        <TableCell>Order Confirmed At</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <DateField
                                locales="en-IN"
                                record={record}
                                source="confirmedAt"
                                showTime
                            />
                        </TableCell>
                    </TableRow>

                    {record.type === OrderType.storage && (
                        <TableRow>
                            <TableCell>Duration</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                {record.months} months
                            </TableCell>
                        </TableRow>
                    )}
                    {record.type === OrderType.storage && (
                        <TableRow>
                            <TableCell>Storage From</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <DateField record={record} source="date" />
                            </TableCell>
                        </TableRow>
                    )}
                    {record.type === OrderType.storage && (
                        <TableRow>
                            <TableCell>Storage Type</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <ChipField
                                    record={record}
                                    source="storageType"
                                />
                            </TableCell>
                        </TableRow>
                    )}

                    {record.type === OrderType.storage && (
                        <TableRow>
                            <TableCell colSpan={2}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell>EXPECTED</TableCell>
                                            <TableCell>ACTUAL</TableCell>
                                            <TableCell>WAREHOUSE</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Quantity</TableCell>
                                            <TableCell>
                                                {record && record.qty}
                                            </TableCell>
                                            <TableCell>
                                                {record && record.actualQty}
                                            </TableCell>
                                            <TableCell>
                                                {record && record.currentQty}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Size</TableCell>
                                            <TableCell>
                                                <VolumeField
                                                    record={record}
                                                    source="size"
                                                    showUnit
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <VolumeField
                                                    record={record}
                                                    source="actualSize"
                                                    showUnit
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <VolumeField
                                                    record={record}
                                                    source="currentSize"
                                                    showUnit
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Rent</TableCell>
                                            <TableCell>
                                                {record && (
                                                    <>
                                                        <Money
                                                            amount={record.rent}
                                                            currency={
                                                                record.currency
                                                            }
                                                            showCurrency
                                                        />{' '}
                                                        / month
                                                    </>
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {record && (
                                                    <>
                                                        <Money
                                                            amount={
                                                                record.actualRent
                                                            }
                                                            currency={
                                                                record.currency
                                                            }
                                                            showCurrency
                                                        />{' '}
                                                        / month
                                                    </>
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {record && (
                                                    <>
                                                        <Money
                                                            amount={
                                                                record.currentRent
                                                            }
                                                            currency={
                                                                record.currency
                                                            }
                                                            showCurrency
                                                        />{' '}
                                                        / month
                                                    </>
                                                )}
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell>Rate</TableCell>
                                            <TableCell>
                                                {record.size && (
                                                    <span>
                                                        <Money
                                                            amount={
                                                                record.customerRent /
                                                                parseFloat(
                                                                    record.size
                                                                )
                                                            }
                                                            currency={
                                                                record.currency
                                                            }
                                                            showCurrency
                                                        />{' '}
                                                        / CBM / month
                                                    </span>
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {record.size && (
                                                    <span>
                                                        <Money
                                                            amount={record.rate}
                                                            currency={
                                                                record.currency
                                                            }
                                                            showCurrency
                                                        />{' '}
                                                        / CBM / month
                                                    </span>
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {record.size && (
                                                    <span>
                                                        <Money
                                                            amount={record.rate}
                                                            currency={
                                                                record.currency
                                                            }
                                                            showCurrency
                                                        />{' '}
                                                        / CBM / month
                                                    </span>
                                                )}
                                            </TableCell>
                                        </TableRow>

                                        <ItemStorageList record={record} />
                                    </TableBody>
                                </Table>
                            </TableCell>
                        </TableRow>
                    )}

                    {record.type === OrderType.storage && (
                        <TableRow>
                            <TableCell>Storage Contents</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                {record.storageContents &&
                                    record.storageContents.map(i => (
                                        <div key={i}>{i}</div>
                                    ))}
                            </TableCell>
                        </TableRow>
                    )}
                    <TableRow>
                        <TableCell>Need Additional Insurance?</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <BooleanField
                                record={record}
                                source="insuranceRequired"
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow hover>
                        <TableCell>Need Survey?</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <BooleanField
                                record={record}
                                source="surveyRequired"
                            />
                        </TableCell>
                    </TableRow>

                    {record.surveyRequired && (
                        <TableRow hover>
                            <TableCell>Survey Date</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <DateField
                                    record={record}
                                    source="surveyDate"
                                />
                            </TableCell>
                        </TableRow>
                    )}
                    {record.surveyRequired && (
                        <TableRow hover>
                            <TableCell>Survey Time</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                {record.surveyTime}
                            </TableCell>
                        </TableRow>
                    )}
                    <TableRow hover>
                        <TableCell colSpan={2} />
                    </TableRow>
                    <TableRow hover>
                        <TableCell colSpan={2}>
                            <strong>Billing</strong>
                        </TableCell>
                    </TableRow>
                    <TableRow hover>
                        <TableCell>Customer Budget</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <MoneyField
                                record={record}
                                source="customerBudget"
                                showCurrency
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow hover>
                        <TableCell>Coupon</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <ChipField record={record} source="couponId" />
                        </TableCell>
                    </TableRow>

                    {record.type === OrderType.storage && (
                        <TableRow>
                            <TableCell>Monthly Rent</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                {record && (
                                    <span>
                                        <Money
                                            amount={record.customerRent}
                                            currency={record.currency}
                                            showCurrency
                                        />{' '}
                                        / month
                                    </span>
                                )}
                            </TableCell>
                        </TableRow>
                    )}
                    {record.type !== OrderType.storage && (
                        <TableRow>
                            <TableCell>Amount</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                {record && (
                                    <span>
                                        <Money
                                            amount={record.amount}
                                            currency={record.currency}
                                            showCurrency
                                        />
                                    </span>
                                )}
                            </TableCell>
                        </TableRow>
                    )}

                    {record.type === OrderType.storage && (
                        <TableRow>
                            <TableCell>
                                <strong>Total Rent</strong>
                            </TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <strong>
                                    <Money
                                        amount={totalRent}
                                        currency={record.currency}
                                        showCurrency
                                    />
                                </strong>
                            </TableCell>
                        </TableRow>
                    )}
                    <TableRow>
                        <TableCell colSpan={2}>
                            <strong>Addional Services</strong>
                        </TableCell>
                    </TableRow>
                    {services &&
                        services.map(i => {
                            if (i && i.id) {
                                if (
                                    (record.months >= 3 &&
                                        (i.servicetype.name === 'Pickup' ||
                                            i.servicetype.name ===
                                                'Pickup and Packing')) ||
                                    (record.months >= 6 &&
                                        i.servicetype.name === 'Delivery')
                                ) {
                                    return (
                                        <TableRow hover key={i.id}>
                                            <TableCell>
                                                {capitalize(i.servicetype.name)}
                                            </TableCell>
                                            <TableCell
                                                style={{ textAlign: 'right' }}
                                            >
                                                Free
                                            </TableCell>
                                        </TableRow>
                                    );
                                } else {
                                    return (
                                        <TableRow hover key={i.id}>
                                            <TableCell>
                                                {capitalize(i.servicetype.name)}
                                            </TableCell>
                                            <TableCell
                                                style={{ textAlign: 'right' }}
                                            >
                                                <Money
                                                    amount={i.amount || 0}
                                                    currency={i.currency}
                                                    showCurrency
                                                />
                                            </TableCell>
                                        </TableRow>
                                    );
                                }
                            }
                        })}
                    <TableRow hover>
                        <TableCell>Total Service Charges</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {!!totalServiceCharges && (
                                <Money
                                    amount={totalServiceCharges}
                                    currency={record.currency}
                                    showCurrency
                                />
                            )}
                        </TableCell>
                    </TableRow>
                    {!!discountAmount && (
                        <TableRow hover>
                            <TableCell>
                                <strong>Sub Total</strong>
                            </TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <strong>
                                    <Money
                                        amount={subTotalAmount}
                                        currency={record.currency}
                                        showCurrency
                                    />
                                </strong>
                            </TableCell>
                        </TableRow>
                    )}
                    {!!discountAmount && (
                        <TableRow hover>
                            <TableCell>Discount</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <Money
                                    amount={discountAmount}
                                    currency={record.currency}
                                    showCurrency
                                />
                            </TableCell>
                        </TableRow>
                    )}
                    <TableRow hover>
                        <TableCell>
                            <strong>Total Amount</strong>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <strong>
                                {!!totalAmount && (
                                    <Money
                                        amount={totalAmount}
                                        currency={record.currency}
                                        showCurrency
                                    />
                                )}
                            </strong>
                        </TableCell>
                    </TableRow>
                    <TableRow hover>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow hover>
                        <TableCell>Delivery (Charged Later)</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {!!deliveryCharge && (
                                <Money
                                    amount={deliveryCharge}
                                    currency={record.currency}
                                    showCurrency
                                />
                            )}
                        </TableCell>
                    </TableRow>
                    <TableRow hover>
                        <TableCell>Payment Mode</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.paymentMode &&
                                capitalize(record.paymentMode)}
                        </TableCell>
                    </TableRow>
                    <TableRow hover>
                        <TableCell colSpan={2} />
                    </TableRow>
                    {isPartnerOrder && (
                        <>
                            <TableRow hover>
                                <TableCell colSpan={2}>
                                    <strong>Partner Commissions</strong>
                                </TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell>Storage Commission</TableCell>
                                <TableCell style={{ textAlign: 'right' }}>
                                    {record.partnerStorageCommissionPercent} %
                                </TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell>Service Commission</TableCell>
                                <TableCell style={{ textAlign: 'right' }}>
                                    {record.partnerServiceCommissionPercent} %
                                </TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell colSpan={2} />
                            </TableRow>
                        </>
                    )}
                    <TableRow hover>
                        <TableCell colSpan={2}>
                            <strong>Logistics</strong>
                        </TableCell>
                    </TableRow>
                    {record.type === OrderType.storage && (
                        <TableRow>
                            <TableCell>Warehouse Type</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                {record.warehouseType &&
                                    record.warehouseType.map(wt => (
                                        <span key="{wt}" className="tags-chip">
                                            {wt.toUpperCase()}
                                        </span>
                                    ))}
                            </TableCell>
                        </TableRow>
                    )}
                    {record.type === OrderType.storage && (
                        <TableRow hover>
                            <TableCell>Type of Operation</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                {record.pickupRequired ? 'Pickup' : 'Drop Off'}
                            </TableCell>
                        </TableRow>
                    )}
                    {record.pickupRequired && (
                        <TableRow hover>
                            <TableCell>Pickup Date</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <DateField
                                    locales="en-IN"
                                    record={record}
                                    source="date"
                                    options={{
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                    }}
                                />
                            </TableCell>
                        </TableRow>
                    )}
                    {record.pickupRequired && (
                        <TableRow hover>
                            <TableCell>Pickup Time</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <TimeSlotField
                                    record={record}
                                    sourceFrom="pickupTimeFrom"
                                    sourceTo="pickupTimeTo"
                                />
                            </TableCell>
                        </TableRow>
                    )}
                    {!record.pickupRequired && (
                        <TableRow hover>
                            <TableCell>Drop Date</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <DateField
                                    locales="en-IN"
                                    record={record}
                                    source="date"
                                    options={{
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                    }}
                                />
                            </TableCell>
                        </TableRow>
                    )}
                    {!record.pickupRequired && (
                        <TableRow hover>
                            <TableCell>Drop Time</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <TimeSlotField
                                    record={record}
                                    sourceFrom="pickupTimeFrom"
                                    sourceTo="pickupTimeTo"
                                />
                            </TableCell>
                        </TableRow>
                    )}
                    <TableRow>
                        <TableCell>Payment To Collect</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.collectAmount && record.currency ? (
                                <Money
                                    amount={record.collectAmount}
                                    currency={record.currency}
                                    showCurrency
                                />
                            ) : (
                                ''
                            )}
                        </TableCell>
                    </TableRow>
                    <TableRow hover>
                        <TableCell>Need Empty Boxes in advance</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.newboxRequired ? 'Yes' : 'No'}
                        </TableCell>
                    </TableRow>
                    {record.newboxRequired && (
                        <TableRow hover>
                            <TableCell>Newbox Delivery Date</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <DateField
                                    locales="en-IN"
                                    record={record}
                                    source="deliveryDate"
                                    options={{
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                    }}
                                />
                            </TableCell>
                        </TableRow>
                    )}
                    {record.newboxRequired && (
                        <TableRow hover>
                            <TableCell>Newbox Delivery Time</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <TimeSlotField
                                    record={record}
                                    sourceFrom="deliveryTimeFrom"
                                    sourceTo="deliveryTimeTo"
                                />
                            </TableCell>
                        </TableRow>
                    )}
                    <TableRow>
                        <TableCell>
                            {record.type === OrderType.moving
                                ? 'Source Address'
                                : 'Address'}
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.addressId && (
                                <Address id={record.addressId} />
                            )}
                        </TableCell>
                    </TableRow>
                    {record.type === OrderType.moving && (
                        <TableRow>
                            <TableCell>Destination Address</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                {record.destAddressId && (
                                    <Address id={record.destAddressId} />
                                )}
                            </TableCell>
                        </TableRow>
                    )}
                    <TableRow>
                        <TableCell>Notes</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.notes}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Customer Need</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.need}
                        </TableCell>
                    </TableRow>
                    <TableRow hover>
                        <TableCell>Channel</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <TextField record={record} source="channel" />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Source</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <TextField record={record} source="source" />
                        </TableCell>
                    </TableRow>
                    <TableRow hover>
                        <TableCell>Tags</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.tags &&
                                record.tags.map(t => (
                                    <span key="{t}" className="tags-chip">
                                        {t}
                                    </span>
                                ))}
                        </TableCell>
                    </TableRow>
                    {record.pickupRequired && (
                        <>
                            <TableRow hover>
                                <TableCell colSpan={2}>
                                    <strong>Checklist</strong>
                                </TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell>1. Type of Building</TableCell>
                                <TableCell style={{ textAlign: 'right' }}>
                                    <TextField
                                        record={record}
                                        source="checklist.buildingType"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell>
                                    2. Is entry permit required?
                                </TableCell>
                                <TableCell
                                    colSpan={2}
                                    style={{
                                        padding: '0px',
                                        borderBottom: 'none',
                                    }}
                                >
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell
                                                    style={{
                                                        borderBottom: 'none',
                                                    }}
                                                >
                                                    <BooleanField
                                                        record={record}
                                                        source="checklist.permitRequired"
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        borderBottom: 'none',
                                                    }}
                                                >
                                                    {record &&
                                                        record.checklist &&
                                                        record.checklist
                                                            .permitRequired && (
                                                            <Thumbnail
                                                                resource="user-photos"
                                                                filter={{
                                                                    caption:
                                                                        Caption.entry_permit,
                                                                }}
                                                                {...props}
                                                            />
                                                        )}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                    </Table>
                                </TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell>
                                    3. Is moving insurance required?
                                </TableCell>
                                <TableCell
                                    style={{
                                        textAlign: 'right',
                                        borderTopWidth: '1px',
                                    }}
                                >
                                    <BooleanField
                                        record={record}
                                        source="checklist.movingInsuranceRequired"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell>
                                    4. Is parking slot available?
                                </TableCell>
                                <TableCell style={{ textAlign: 'right' }}>
                                    <BooleanField
                                        record={record}
                                        source="checklist.parkingSlotAvailable"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell>
                                    5. Is parking slot inside or outside the
                                    building?
                                </TableCell>
                                <TableCell style={{ textAlign: 'right' }}>
                                    <TextField
                                        record={record}
                                        source="checklist.isSlotInsideOrOutside"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell>
                                    6. Maximum allowed time for loading
                                </TableCell>
                                <TableCell style={{ textAlign: 'right' }}>
                                    <TextField
                                        record={record}
                                        source="checklist.maximumAllowedLoadingTime"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell>7. Building Floor</TableCell>
                                <TableCell style={{ textAlign: 'right' }}>
                                    <TextField
                                        record={record}
                                        source="checklist.buildingFloor"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell>8. Is lift available?</TableCell>
                                <TableCell style={{ textAlign: 'right' }}>
                                    <BooleanField
                                        record={record}
                                        source="checklist.liftAvailable"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell>
                                    9. Maximum allowed time for using the lift
                                </TableCell>
                                <TableCell style={{ textAlign: 'right' }}>
                                    <TextField
                                        record={record}
                                        source="checklist.maximumAllowedLiftTime"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell>
                                    10. Are there items that would not fit
                                    inside the lift?
                                </TableCell>
                                <TableCell style={{ textAlign: 'right' }}>
                                    <BooleanField
                                        record={record}
                                        source="checklist.anyItemsNotFitinsideLift"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell>11. If yes details</TableCell>
                                <TableCell style={{ textAlign: 'right' }}>
                                    <TextField
                                        record={record}
                                        source="checklist.liftNotFitDetails"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell>
                                    12. If customer wants to authorize someone?
                                </TableCell>
                                <TableCell style={{ textAlign: 'right' }}>
                                    <BooleanField
                                        record={record}
                                        source="checklist.authorizeOtherPerson"
                                    />
                                </TableCell>
                            </TableRow>
                            {record.checklist?.authorizeOtherPerson && (
                                <TableRow hover>
                                    <TableCell>
                                        13. Authorized Person Name
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'right' }}>
                                        <TextField
                                            record={record}
                                            source="checklist.authorizedName"
                                        />
                                    </TableCell>
                                </TableRow>
                            )}
                            {record.checklist?.authorizeOtherPerson && (
                                <TableRow hover>
                                    <TableCell>
                                        14. Authorized Person Phone
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'right' }}>
                                        <TextField
                                            record={record}
                                            source="checklist.authorizedPhone"
                                        />
                                    </TableCell>
                                </TableRow>
                            )}
                            {record.checklist?.authorizeOtherPerson && (
                                <TableRow hover>
                                    <TableCell>
                                        15. Authorized Person ID Number
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'right' }}>
                                        <TextField
                                            record={record}
                                            source="checklist.authorizedID"
                                        />
                                    </TableCell>
                                </TableRow>
                            )}
                        </>
                    )}
                </TableBody>
            </Table>
        </div>
    );
}

LeadSummary.propTypes = {
    record: PropTypes.object,
};

LeadSummary.defaultProps = {
    record: {},
};
