import React from 'react';
import Location from './Location';
import { FieldProps, Storage } from '../../types';

const StorageLocation: React.FC<FieldProps<Storage>> = ({ record }) => {
    console.log('record', record);
    // const storageRecord =
    //     record &&
    //     record.storagerecords &&
    //     !!record.storagerecords.length &&
    //     record.storagerecords[0];
    // const storageRecord =
    // record &&
    // record.storagerecords &&
    // !!record.storagerecords.length &&
    // record.storagerecords.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))[0];
    const storageRecord =
        record &&
        record.storagerecords &&
        !!record.storagerecords.length &&
        record.storagerecords.sort(
            (a, b) =>
                new Date(b.updatedAt).getTime() -
                new Date(a.updatedAt).getTime()
        )[0];

    if (storageRecord) {
        console.log('storageRecord', storageRecord);
        if (storageRecord.status === 'out') {
            console.log('storageRecord.status', storageRecord.status);

            return <span>OUT</span>;
        }
        console.log('before storageRecord.locationId', storageRecord);

        if (storageRecord.status === 'in' && storageRecord.locationId) {
            console.log(' storageRecord.locationId', storageRecord);

            return <Location record={storageRecord} label="Location" />;
        }

        return <span>IN</span>;
    }

    return null;
};

export default StorageLocation;
